import React from 'react'
import styled, { keyframes } from 'styled-components'

import return_arrow from '../../../assets/icons/golden_arrow_left.svg'
import dio from '../../../assets/slidesimg/dio.jpg'
import green_diago from '../../../assets/slidesimg/green_diago.png'
import { Howl } from 'howler'
import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import './styles.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

const float = () => keyframes`
	0% {
    opacity:$ 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 0.8;

	
    transform: translate(-3px, 5px) scale(0.99) rotate(0.3deg);
   
	}

	50% {
    opacity 1;

    transform: translate(-5px, 0px) scale(0.98) rotate(0deg);
   
	}

  	75% {
    opacity 0.6;

	
    transform: translate(-3px, -5px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.0);
		transform: translate(0px) rotate(0deg);
	}
`

const float2 = () => keyframes`
	0% {
    opacity: 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 1;

	
    transform: translate(3px, -5px) scale(0.99) rotate(0.3deg);
   
	}

    	40% {
    opacity 1;
	}

	50% {
    opacity 0.5;
    transform: translate(5px, -3px) scale(0.98) rotate(0deg);
   
	}

  	60% {
    opacity 1;
	}

  	75% {
    opacity 1;

	
    transform: translate(3px, 0px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		transform: translate(0px) rotate(0deg);
	}
`
// filter: saturate(1);
const GodImg = styled.img`
    display: none;
    width: 100%;
    max-height: 100vh;
    object-fit: contain;
    position: absolute;

    @media ${device.desktop} {
        left: -28vh;
    }

    @media ${device.smallest_laptops} {
        left: -28vh;
    }
    @media ${device.large_desktop} {
        left: -30vh;
    }

    @media ${device.ipad} {
        left: -10vw;
    }

    transition: all 100ms;
    animation-name: ${float};
    animation-duration: 12000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`
const StyledVideo = styled.video`
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity, 1s ease-in-out;

    @media ${device.desktop} {
        transform: translateX(-28vh);
    }

    @media ${device.smallest_laptops} {
        transform: translateX(-28vh);
    }
    @media ${device.large_desktop} {
        transform: translateX(-30vh);
    }

    @media ${device.ipad} {
        height: 60%;
        transform: translateX(-15vw);
    }
`

const NeoImg = styled.img`
    position: absolute;
    height: 100vh;
    top: -5vh;
    left: 20vw;

    @media ${device.ipad} {
        height: 80vh;
        left: 0vw;
        top: 5vh;
    }

    @media ${device.large_desktop} {
        left: 30vw;
        height: 100vh;
        top: -5vh;
    }
    z-index: 2;
`

const TextWrapper = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 8000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
    right: 10vw;

    @media ${device.large_desktop} {
        right: 19vw;
    }

    @media ${device.smallest_laptops} {
        right: 10vw;
    }

    @media ${device.ipad} {
        right: 5vw;
    }

    width: 300px;
    padding-top: 10vh;
`

const Subtitle = styled.div`
    font-family: antonio-extralight;
    font-size: 31px;

    @media ${device.large_desktop} {
        font-size: 50px;
    }

    text-transform: uppercase;
    color: white;
`

const ReturnText = styled.div`
    font-family: antonio-extralight;
    font-size: 90px;

    @media ${device.large_desktop} {
        font-size: 150px;
    }

    letter-spacing: 0em;
    text-transform: uppercase;
    color: white;
    transform: translateY(50px);
    width: 400px;
`

const StyledLink = styled.a`
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

type Istyled2 = {
    idHovered: string
}
const Arrow = styled.img<Istyled2>`
    transition: 150ms;

    opacity: ${(props) => (props.idHovered === 'arrow' ? 1 : 0)};
    width: 20px;

    @media ${device.desktop} {
        width: 20px;
    }

    @media ${device.large_desktop} {
        width: 30px;
    }
    transform: ${(props) =>
        props.idHovered === 'arrow'
            ? 'rotate(0deg) translate(0px, 50px)'
            : 'rotate(0deg) translate(-20px, 50px)'};
`

// background-color: white;
const WhiteLine = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 30vh;
        right: 11vw;
        bottom: 0px;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 10vw;
    }

    @media ${device.large_desktop} {
        font-size: 10px;
    }
`

// background-color: white;
const WhiteLine3 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 25vh;
        right: 15vw;
        bottom: -8vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 15vw;
    }

    @media ${device.large_desktop} {
        right: 12vw;
        font-size: 11px;
    }
`
// background-color: white;
const WhiteLine2 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 90vh;
        left: 10vw;
        bottom: -160vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 2px;
    }
`

type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

let sound = new Howl({
    src: ['/electric_glitch.wav'],
    autoplay: true,
    loop: false,
    volume: 0.01,
    onend: function () {
        console.log('Finished!')
    },
})

class HomepageSlide1 extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        let url = dio

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        let { idHovered } = this.state

        return (
            <>
                <PageWrapper>
                    <NeoImg src={green_diago} alt="neon" />
                    <GodImg
                        src={dio}
                        alt="god"
                        style={{
                            paddingTop: '0px',
                        }}
                    />
                    <StyledVideo
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        data-keepplaying
                        preload={'auto'}
                        ref={(ref) => 'video_dio'}
                        onLoadedData={() =>
                            this.setState({ isVideoLoading: false })
                        }
                        style={{ opacity: this.state.isVideoLoading ? 0 : 1 }}
                    >
                        <source src={'/dio_video.mp4'} type="video/mp4" />
                    </StyledVideo>

                    <TextWrapper>
                        <StyledLink
                            href="https://gods.nrus.io"
                            target="_blank"
                            onMouseOver={() => {
                                this.setIdHovered('arrow')
                                sound.play()
                            }}
                            onMouseLeave={() => this.setIdHovered('')}
                        >
                            <ReturnText
                            // className="glitch"
                            // data-glitch={'the gods'}
                            >
                                GODS
                            </ReturnText>

                            <div style={{ paddingTop: '58px' }} />
                            <Subtitle>
                                <div>
                                    <span id="L">E</span>
                                    <span id="I">X</span>
                                    <span id="G">P</span>
                                    <span id="H">L</span>
                                    <span id="T">O</span>
                                    <span id="H">R</span>
                                    <span id="S">E</span>
                                </div>
                            </Subtitle>
                            <div style={{ paddingTop: '30px' }} />
                            <Arrow
                                idHovered={idHovered}
                                src={return_arrow}
                                alt="return"
                            />
                        </StyledLink>
                    </TextWrapper>
                </PageWrapper>
                <WhiteLine>
                    01001000 <span> - </span>01010101 <span> - </span>
                    01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine>
                <WhiteLine3>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine3>
                <WhiteLine2>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                    <span> - </span> 01001000 <span> - </span>01010101
                    <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine2>
            </>
        )
    }
}

export default HomepageSlide1
