import React from 'react'
import styled, { keyframes } from 'styled-components'

import nath from '../../../assets/slidesimg/nath.png'
import nath_txt from '../../../assets/slidesimg/nath_txt.png'
import { Howl } from 'howler'
import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import '../styles.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
        padding-left: 30px;
    }
`

const float2 = () => keyframes`
	0% {
    opacity: 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 1;

	
    transform: translate(3px, -5px) scale(0.99) rotate(0.3deg);
   
	}

    	40% {
    opacity 1;
	}

	50% {
    opacity 0.5;
    transform: translate(5px, -3px) scale(0.98) rotate(0deg);
   
	}

  	60% {
    opacity 1;
	}

  	75% {
    opacity 1;

	
    transform: translate(3px, 0px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		transform: translate(0px) rotate(0deg);
	}
`
// filter: saturate(1);
const GodImg = styled.img`
    margin-top: 10vh;
    display: none;
    width: 40vw;
    max-height: 60vh;
    object-fit: contain;

    @media ${device.desktop} {
    }

    @media ${device.smallest_laptops} {
    }
    @media ${device.large_desktop} {
    }

    @media ${device.ipad} {
    }
`

const StyledVideo = styled.video`
    margin-top: 10vh;
    width: 40vw;
    max-height: 80vh;
    transform: translate(-1vw, 5vh);

    @media ${device.smallest_laptops} {
        width: 40vw;
        max-height: 80vh;
    }

    @media ${device.large_desktop} {
        width: 30vw;
        max-height: 80vh;
    }
`

const TextWrapper = styled.div`
    display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 8000ms;
    transition: all ease-in-out infinite;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;

    width: 300px;
    padding-top: 10vh;
`

const ReturnText = styled.div`
    font-family: antonio-extralight;
    font-size: 90px;
    @media ${device.large_desktop} {
        font-size: 60px;
    }

    letter-spacing: 0em;
    text-transform: uppercase;
    color: white;
    transform: translate(4vw, 50px);
    width: 500px;
    color: white;
`

const StyledLink = styled.div`
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const NathTxt = styled.img`
    width: 400px;
    transform: translate(4vw, 100px) rotate(-90deg);
    opacity: 1;
    z-index: 6;
`

const WhiteLine = styled.div`
    background-color: black;
    width: 1px;
    height: 20vh;
    z-index: 5;
    transform: translateX(5vw);
`

const SmallLine = styled.div`
    background-color: white;
    width: 30px;
    height: 1px;
`

const TextWrap2 = styled.div`
    margin-top: -50px;

    transform: translateX(-2vw);

    @media ${device.large_desktop} {
        transform: translateX(-2vw);
    }
`

const Text1 = styled.div`
    font-size: 60px;
    font-family: Antonio-ExtraLight;
    opacity: 1;
    color: #24ff00;
`

const Text2 = styled.a`
    font-size: 30px;
    font-family: Antonio-ExtraLight;
    color: grey;
    padding-bottom: 10px;
    text-decoration: none;
`
const Col = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: #030109;
`

const Col2 = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    transform: translateY(0px);
    background-color: #030109;
`
type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

let sound = new Howl({
    src: ['/electric_glitch-2.wav'],
    autoplay: true,
    loop: false,
    volume: 0.01,
    onend: function () {
        console.log('Finished!')
    },
})

class HomepageSlide3 extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        let url = nath

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <PageWrapper>
                    <TextWrapper>
                        <WhiteLine />
                        {/* <EstTxt> </EstTxt> */}

                        <StyledLink
                            // href="https://gods.nrus.io"
                            // target="_blank"
                            onMouseOver={() => {
                                // this.setIdHovered('arrow')
                                sound.play()
                            }}
                            onMouseLeave={() => this.setIdHovered('')}
                        >
                            <ReturnText
                            // className="glitch-2"
                            // data-glitch-2={'the gods'}
                            >
                                about
                            </ReturnText>

                            <div style={{ paddingTop: '120px' }} />

                            <NathTxt src={nath_txt} alt="nathalia rus" />

                            <div style={{ paddingTop: '40px' }} />
                        </StyledLink>
                        <WhiteLine />
                    </TextWrapper>

                    <GodImg
                        src={nath}
                        alt="god"
                        style={{
                            paddingTop: '0px',
                        }}
                    />

                    <StyledVideo
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        data-keepplaying
                        preload={'auto'}
                        ref={(ref) => 'video_nath'}
                        onLoadedData={() =>
                            this.setState({ isVideoLoading: false })
                        }
                        style={{ opacity: this.state.isVideoLoading ? 0 : 1 }}
                    >
                        <source src={'/nath.mp4'} type="video/mp4" />
                    </StyledVideo>

                    <TextWrap2>
                        <Col>
                            <div
                                style={{
                                    fontFamily: 'Antonio-Regular',
                                    fontSize: '5rem',
                                    color: '#845c8b3b',
                                    paddingBottom: '10px',
                                }}
                            >
                                NATHALIA RUS
                            </div>
                            <Text1>ENGINEER.</Text1>
                            <Text1>ARTIST.</Text1>
                            <Text1>FOUNDER.</Text1>
                        </Col>
                        <div style={{ paddingTop: '58px' }} />
                        <SmallLine />

                        <div style={{ paddingTop: '58px' }} />
                        <Col2>
                            <Text2
                                className="glitch-2"
                                data-glitch-2={'Instagram'}
                                href="https://www.instagram.com/yeahgirlscode"
                                target="_blank"
                                style={{ top: '0px' }}
                                onMouseOver={() => {
                                    this.setIdHovered('instagram')
                                    sound.play()
                                }}
                            >
                                Instagram
                            </Text2>
                            <Text2
                                href="https://twitter.com/yeahgirlscode"
                                target="_blank"
                                className="glitch-2"
                                data-glitch-2={'Twitter'}
                                style={{ top: '50px' }}
                                onMouseOver={() => {
                                    this.setIdHovered('twitter')
                                    sound.play()
                                }}
                            >
                                Twitter
                            </Text2>
                            <Text2
                                className="glitch-2"
                                data-glitch-2={'Behance'}
                                href="https://www.behance.net/nathaliarus"
                                target="_blank"
                                style={{ top: '100px' }}
                                onMouseOver={() => {
                                    this.setIdHovered('behance')
                                    sound.play()
                                }}
                            >
                                Behance
                            </Text2>

                            <Text2
                                className="glitch-2"
                                data-glitch-2={'SoundCloud'}
                                href="https://soundcloud.com/nathaliarus"
                                target="_blank"
                                style={{ top: '150px' }}
                                onMouseOver={() => {
                                    this.setIdHovered('soundcloud')
                                    sound.play()
                                }}
                            >
                                SoundCloud
                            </Text2>
                        </Col2>
                    </TextWrap2>
                </PageWrapper>
            </>
        )
    }
}

export default HomepageSlide3
