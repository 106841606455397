import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import styled from 'styled-components'
import MainThemePaginatorMenu from '../../atoms/paginator/mainThemePaginatorMenu'
import HomepageSlide3 from '../../molecules/homepageSlidesDesktop/homepageSlide3'
import './styles.css'
import { device } from '../../templates/devices/devices'

import { Howl } from 'howler'
import sound_on from '../../../assets/icons/sound_on.svg'
import sound_off from '../../../assets/icons/sound_off.svg'
import { motion } from 'framer-motion'
import HomepageSlide1 from '../../molecules/homepageSlidesDesktop/homepageSlide1'
import HomepageSlide2 from '../../molecules/homepageSlidesDesktop/homepageSlide2'
import HomepageSlide3Mobile from '../../molecules/homepageSlidesMobile/homepageSlide3Mobile'
import HomepageSlide1Mobile from '../../molecules/homepageSlidesMobile/homepageSlide1Mobile'
import HomepageSlide2Mobile from '../../molecules/homepageSlidesMobile/homepageSlide2Mobile'
import HomepageSlide002 from '../../molecules/homepageSlidesDesktop/homepageSlide002'
import HomepageSlide002Mobile from '../../molecules/homepageSlidesMobile/homepageSlide002Mobile'
import WelcomePageSlideDesktop from '../../molecules/homepageSlidesDesktop/welcomePageSlide'
import WelcomePageSlideMobile from '../../molecules/homepageSlidesMobile/welcomePageSlide'

type StyledProps = {
    transformYN?: string
}

const Wrapper = styled.div`
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media ${device.beyond_ipad_mobile} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.mobile_and_ipad} {
        padding-right: 0px;
        padding-left: 0px;
    }
`
const TransformYWrap = styled.div<StyledProps>`
    @media ${device.beyond_ipad_mobile} {
        position: relative;
        height: 100%;
        wifth: 100%;
        transform: ${(props) =>
            props.transformYN ? `translateY(${props.transformYN})` : 'auto'};
    }
`

const TransformYWrapMobile = styled.div<StyledProps>`
    @media ${device.mobile_and_ipad} {
        position: relative;
        height: 100%;
        width: 100%;
        transform: ${(props) =>
            props.transformYN ? `translateY(${props.transformYN})` : 'auto'};
    }
`

const SoundRec = styled.div`
    position: absolute;
    background-color: #00e7ff14;
    border: 1px solid #3b3b3b;
    z-index: 100;

    @media ${device.beyond_ipad_mobile} {
        left: 24px;
        top: 20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
        height: 80px;
        width: 40px;

        cursor: pointer;
        :hover {
            background-color: #005aff29;
        }
    }

    @media ${device.mobile_and_ipad} {
        top: 10px;
        left: 10px;
        height: 60px;
        width: 30px;
    }
`

const SoundIcon = styled.img`
    @media ${device.beyond_ipad_mobile} {
        height: 18px;
    }

    @media ${device.mobile_and_ipad} {
        width: 15px;
    }
`

const MotionDivCol = styled(motion.div)`
    @media ${device.beyond_ipad_mobile} {
        font-size: 14px;
    }

    @media ${device.mobile_and_ipad} {
        font-size: 10px;
    }

    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    font-family: 'Antonio-ExtraLight';
    text-transform: uppercase;
`

type LocalState = {
    currentSection: number
    totalSections: number
    isSoundPlaying?: boolean
}

class MainPaginator extends React.Component<{}, LocalState> {
    state = {
        currentSection: 1,
        totalSections: 4,
        isSoundPlaying: false,
    }

    setIsSoundPlaying = (arg?: boolean) => {
        return this.setState({ ...this.state, isSoundPlaying: arg })
    }
    componentWillUnmount() {
        document.getElementById('paginator')!.style.display = 'none'
    }

    ambiance = new Howl({
        src: ['/ambiance.wav'],
        autoplay: false,
        loop: true,
        volume: 0.2,
        onload: function () {
            console.log('loaded!')
        },
        onend: function () {
            console.log('Finished!')
        },
    })

    render() {
        const changeSection = (section: number) => {
            this.setState({ currentSection: section })
        }

        let sections_list_gen = () => {
            // let number: number = Math.floor(gods_list.length / 3) + 2
            let number = 5
            let section_list: string[] = Array.from(
                { length: number },
                (el: any, index: number) => index.toString()
            )

            return section_list
        }

        let section_list = sections_list_gen()

        let { isSoundPlaying } = this.state

        return (
            <div>
                <SoundRec
                    onClick={() => {
                        if (isSoundPlaying === true) {
                            this.setIsSoundPlaying(false)
                            this.ambiance.pause()
                        } else {
                            this.setIsSoundPlaying(true)
                            this.ambiance.play()
                        }
                    }}
                >
                    <MotionDivCol
                        whileTap={{ scale: 1.2, transition: { duration: 0.3 } }}
                    >
                        {isSoundPlaying ? (
                            <>
                                <SoundIcon src={sound_on} alt="sound" />
                                <div style={{ paddingTop: '8px' }}>on</div>
                            </>
                        ) : (
                            <>
                                <SoundIcon src={sound_off} alt="sound" />
                                <div style={{ paddingTop: '8px' }}>off</div>
                            </>
                        )}
                    </MotionDivCol>
                </SoundRec>

                <ReactFullpage
                    //fullpage options
                    licenseKey={'C49A94F7-3AA84744-B2808534-82E8BCD1'}
                    scrollingSpeed={800} /* Options here */
                    fixedElements="#paginator"
                    anchors={section_list}
                    onEnter={(
                        section: any,
                        origin: any,
                        destination: any,
                        direction: any
                    ) => {
                        changeSection(origin.index)
                    }}
                    onLeave={(
                        section: any,
                        origin: any,
                        destination: any,
                        direction: any
                    ) => {
                        changeSection(origin.index + 1)
                    }}
                    render={({ ...fullpageprops }) => {
                        return (
                            <ReactFullpage.Wrapper>
                                <MainThemePaginatorMenu
                                    paginationtextDesktop={`${
                                        this.state.currentSection
                                    } / ${this.state.totalSections - 1}`}
                                    paginationtextMobile={`${this.state.currentSection} / ${this.state.totalSections}`}
                                    down={
                                        this.state.currentSection ===
                                        this.state.totalSections
                                            ? false
                                            : true
                                    }
                                    up={
                                        this.state.currentSection === 1
                                            ? false
                                            : true
                                    }
                                    goDown={(e?: any) => {
                                        e && e.preventDefault()
                                    }}
                                    goUp={(e: any) => {
                                        e && e.preventDefault(e)
                                    }}
                                    currentSectionIndex={
                                        this.state.currentSection
                                    }
                                    section_list={section_list}
                                />
                                {/* NUMBER 1 */}
                                <Wrapper className="section fp-auto-height">
                                    <TransformYWrap transformYN={'0'}>
                                        <WelcomePageSlideDesktop />
                                    </TransformYWrap>

                                    <TransformYWrapMobile transformYN={'0px'}>
                                        <WelcomePageSlideMobile />
                                    </TransformYWrapMobile>
                                </Wrapper>

                                <Wrapper className="section fp-auto-height">
                                    <TransformYWrap transformYN={'0vh'}>
                                        <HomepageSlide1 />
                                    </TransformYWrap>

                                    <TransformYWrapMobile transformYN={'0px'}>
                                        <HomepageSlide1Mobile />
                                    </TransformYWrapMobile>
                                </Wrapper>
                                {/* NUMBER 002 */}
                                <Wrapper className="section fp-auto-height">
                                    <TransformYWrap transformYN={'-8vh'}>
                                        <HomepageSlide002 />
                                    </TransformYWrap>

                                    <TransformYWrapMobile transformYN={'0px'}>
                                        <HomepageSlide002Mobile />
                                    </TransformYWrapMobile>
                                </Wrapper>

                                {/* NUMBER 2 */}
                                <Wrapper className="section fp-auto-height">
                                    <TransformYWrap transformYN={'-8vh'}>
                                        <HomepageSlide2 />
                                    </TransformYWrap>

                                    <TransformYWrapMobile transformYN={'0px'}>
                                        <HomepageSlide2Mobile />
                                    </TransformYWrapMobile>
                                </Wrapper>
                                {/* NUMBER 3 */}
                                <Wrapper className="section fp-auto-height">
                                    <TransformYWrap transformYN={'-10vh'}>
                                        <HomepageSlide3 />
                                    </TransformYWrap>

                                    <TransformYWrapMobile transformYN={'0px'}>
                                        <HomepageSlide3Mobile />
                                    </TransformYWrapMobile>
                                </Wrapper>
                            </ReactFullpage.Wrapper>
                        )
                    }}
                />
            </div>
        )
    }
}

export default MainPaginator
