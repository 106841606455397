import React from 'react'
import styled, { keyframes } from 'styled-components'
import mania from '../../../assets/slidesimg/mania.png'
import coming_soon from '../../../assets/slidesimg/two_coming_soon.png'
import est from '../../../assets/slidesimg/est.png'
import { Howl } from 'howler'
import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import './styles.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

const float2 = () => keyframes`
	0% {
    opacity: 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 1;

	
    transform: translate(3px, -5px) scale(0.99) rotate(0.3deg);
   
	}

    	40% {
    opacity 1;
	}

	50% {
    opacity 0.5;
    transform: translate(5px, -3px) scale(0.98) rotate(0deg);
   
	}

  	60% {
    opacity 1;
	}

  	75% {
    opacity 1;

	
    transform: translate(3px, 0px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		transform: translate(0px) rotate(0deg);
	}
`
// filter: saturate(1);
const GodImg = styled.img`
    margin-top: 20vh;
    width: 40vw;
    max-height: 80vh;
    object-fit: contain;

    @media ${device.desktop} {
    }

    @media ${device.smallest_laptops} {
        width: 30vw;
    }
    @media ${device.large_desktop} {
    }

    @media ${device.ipad} {
    }
`

const ComingSoonWrap = styled.div`
    color: white;
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    font-family: Antonio-ExtraLight;
    color: grey;
    position: absolute;
    right: 5vw;
    font-size: 16px;

    @media ${device.large_desktop} {
        right: 10vw;
        font-size: 16px;
    }
`

const ComingSoonImg = styled.img`
    width: 20vw;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 8000ms;
    animation-transition: all ease-in-out infinite;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }

    @media ${device.ipad} {
    }

    width: 300px;
    padding-top: 10vh;
`

const Subtitle = styled.div`
    font-family: antonio-extralight;
    font-size: 31px;

    @media ${device.large_desktop} {
        font-size: 30px;
    }

    text-transform: uppercase;
    color: white;
    transform: translateX(-5vw);
`

const ReturnText = styled.div`
    font-family: antonio-extralight;
    font-size: 73px;
    @media ${device.smallest_laptops} {
        font-size: 73px;
    }

    @media ${device.ipad} {
        font-size: 50px;
    }

    @media ${device.large_desktop} {
        font-size: 80px;
    }

    letter-spacing: 0em;
    text-transform: uppercase;
    color: white;
    transform: translate(-5vw, 50px);
    width: 500px;
`

const StyledLink = styled.div`
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

type Istyled2 = {
    idHovered: string
}
const Arrow = styled.img<Istyled2>`
    transition: 150ms;

    width: 200px;

    @media ${device.desktop} {
        width: 200px;
    }

    @media ${device.large_desktop} {
        width: 200px;
    }
    opacity: ${(props) => (props.idHovered === 'arrow' ? 1 : 0)};
    transform: ${(props) =>
        props.idHovered === 'arrow'
            ? 'rotate(90deg) translate(80px, 50px)'
            : 'rotate(90deg) translate(60px, 50px)'};
`

// background-color: white;
const WhiteLine = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        text-align: left;
        display: block;
        position: absolute;
        width: 1px;
        height: 20vh;
        right: 5vw;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 12px;
    }
`
// background-color: white;
const WhiteLine2 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        text-align: left;
        width: 1px;
        height: 20vh;
        right: 8vw;
        bottom: -10vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 12px;
    }
`
const WhiteLine3 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        text-align: left;
        background-color: white;
        position: absolute;
        width: 10vw;
        height: 1px;
        display: block;
        left: 5vw;
        top: 30vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }
`

const WhiteLine4 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        text-align: left;
        position: absolute;
        display: block;
        background-color: white;
        width: 20vw;
        height: 1px;
        left: 8vw;
        bottom: 20vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 1px;
    }

    @media ${device.ipad} {
        font-size: 6px;
        letter-spacing: 1px;
    }

    @media ${device.large_desktop} {
        font-size: 12px;
        letter-spacing: 5px;
    }
`

const EstTxt = styled.div`
    font-size: 73px;
    @media ${device.smallest_laptops} {
        font-size: 73px;
    }

    @media ${device.ipad} {
        font-size: 50px;
    }

    @media ${device.large_desktop} {
        font-size: 80px;
    }
    font-family: Antonio-Regular;
    transform: translate(-5vw, 50px);
    opacity: 0.08;
    width: 500px;
    color: #fff700;
`

type Props = {}

type State = {
    src: any
    idHovered: string
}

let sound = new Howl({
    src: ['/electric_glitch.wav'],
    autoplay: true,
    loop: false,
    volume: 0.01,
    onend: function () {
        console.log('Finished!')
    },
})

class HomepageSlide2 extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
    }
    video: any
    componentDidMount() {
        let url = mania

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        let { idHovered } = this.state

        return (
            <>
                <WhiteLine3>
                    {/* 01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110 */}
                </WhiteLine3>
                <WhiteLine4 />
                <PageWrapper>
                    <TextWrapper>
                        <EstTxt> COMING SOON </EstTxt>
                        <StyledLink
                            // href="https://gods.nrus.io"
                            // target="_blank"
                            onMouseOver={() => {
                                this.setIdHovered('arrow')
                                sound.play()
                            }}
                            onMouseLeave={() => this.setIdHovered('')}
                        >
                            <ReturnText
                            // className="glitch"
                            // data-glitch={'the gods'}
                            >
                                8 TYPES OF LOVE
                                {/* <div>
                                    <span id="L">G</span>
                                    <span id="I">O</span>
                                    <span id="G">D</span>
                                    <span id="H">S</span>
                                </div> */}
                            </ReturnText>

                            <div style={{ paddingTop: '58px' }} />
                            <Subtitle>
                                <div>
                                    <span id="L">T</span>
                                    <span id="I">I</span>
                                    <span id="G">M</span>
                                    <span id="H">E</span>
                                    <span id="T">L</span>
                                    <span id="H">E</span>
                                    <span id="S">S</span>
                                    <span id="S">S</span>
                                    <span> </span>
                                    <span id="L">C</span>
                                    <span id="H">O</span>
                                    <span id="G">L</span>
                                    <span id="H">L</span>
                                    <span id="T">E</span>
                                    <span id="T">C</span>
                                    <span id="H">T</span>
                                    <span id="S">I</span>
                                    <span id="G">O</span>
                                    <span id="H">N</span>
                                    <span id="T">S</span>
                                </div>
                            </Subtitle>
                            <div style={{ paddingTop: '30px' }} />

                            <Arrow
                                idHovered={idHovered}
                                src={est}
                                alt="return"
                            />
                        </StyledLink>
                    </TextWrapper>

                    <GodImg
                        src={mania}
                        alt="god"
                        style={{
                            paddingTop: '0px',
                        }}
                    />

                    <ComingSoonWrap>
                        EST. AUTUMN 2022
                        <ComingSoonImg src={coming_soon} alt="coming soon" />
                    </ComingSoonWrap>
                </PageWrapper>
                <WhiteLine>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine>

                <WhiteLine2>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine2>
            </>
        )
    }
}

export default HomepageSlide2
