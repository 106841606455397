import React from 'react'
import styled from 'styled-components'

import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'

import naiads_phone from '../../../assets/naiads/naiads_phone.png'

const PageWrapper = styled.div`
    display: none;
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

// background-color: white;
const WhiteLine = styled.div`
    position: absolute;
    width: 1px;
    height: 30vh;
    right: 30vw;
    bottom: -15vh;
    z-index: 5;
    overflow-wrap: break-word;
    font-family: Antonio-Regular;
    font-weight: 800;
    font-size: 5px;
    letter-spacing: 0px;
`

// background-color: white;
const WhiteLine1 = styled.div`
    position: absolute;
    width: 1px;
    height: 30vh;
    right: 35vw;
    bottom: -10vh;
    z-index: 5;
    overflow-wrap: break-word;
    font-family: Antonio-Regular;
    font-weight: 800;
    font-size: 4px;
    letter-spacing: 0px;
`

const NaiadsSectionImage = styled.img`
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: 0;
`

type Props = {}

type State = {
    src: any
}

class HomepageSlide002Mobile extends React.Component<Props, State> {
    state = {
        src: '',
    }
    video: any
    componentDidMount() {
        let url = naiads_phone

        this.onLoad(url)
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <PageWrapper>
                    <WhiteLine>
                        01001000 <span> - </span>01010101 <span> - </span>
                        01001101
                        <span> - </span>01000001 <span> - </span>01001110
                    </WhiteLine>
                    <WhiteLine1>
                        01001000 <span> - </span>01010101 <span> - </span>
                        01001101
                        <span> - </span>01000001 <span> - </span>01001110
                    </WhiteLine1>

                    <NaiadsSectionImage src={this.state.src} alt="naiads" />
                </PageWrapper>
            </>
        )
    }
}

export default HomepageSlide002Mobile
