import React from 'react'
import styled from 'styled-components'
import MainPaginator from '../organisms/fullpageScroll/mainPaginator'
import { device } from '../templates/devices/devices'

import './styles.css'

const PageWrapper = styled.div``

const About = styled.a`
    z-index: 20;
    position: fixed;
    font-family: Antonio-Light;
    // color: #845c8b;
    color: white;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 100ms all ease-in;

    :hover {
        color: #00ffffbc;
        transition: 100ms all ease-in;
    }

    @media ${device.beyond_ipad_mobile} {
        font-size: 19px;
        right: 30px;
        top: 30px;
    }

    @media ${device.large_desktop} {
        font-size: 22px;
    }

    @media ${device.mobile_and_ipad} {
        right: 20px;
        bottom: 20px;
        font-size: 16px;
    }
`

class Home extends React.Component<any> {
    componentDidMount() {}
    render() {
        return (
            <>
                <PageWrapper>
                    <About href="#3" className="glitch" data-glitch={'ABOUT'}>
                        ABOUT
                    </About>

                    <MainPaginator />
                </PageWrapper>
            </>
        )
    }
}

export default Home
