import React from 'react'
import styled, { keyframes } from 'styled-components'

import bg_img_url from '../../../assets/slidesimg/welcome/desktop_welcome_bg.png'
import grid_img from '../../../assets/slidesimg/welcome/desktop_welcome_grid.png'
import welcome_glitch from '../../../assets/slidesimg/welcome/welcome_glitch.png'

import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import './styles.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

const float = () => keyframes`
	0% {


		transform: translate(0px) rotate(0deg);
	}
	25% {
    transform: translate(-3px, 5px) scale(1) rotate(0.3deg);
   
	}

	50% {
    opacity 1;
    transform: translate(-5px, 0px) scale(0.98) rotate(0deg);
   
	}

  	75% {
    transform: translate(-3px, -5px) scale(1) rotate(-0.3deg);
   
	}

	100% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.0);
		transform: translate(0px) rotate(0deg);
	}
`

const float2 = () => keyframes`
	0% {
    opacity: 1;
    filter: saturate(1.1);
		transform: translate(0px) scale(1) rotate(0deg);
	}
	25% {
	    filter: saturate(1);
    transform: translate(3px, -5px) scale(0.99) rotate(0.1deg);
   
	}

    	40% {
    filter: saturate(1.1) scale(1);
	}

	50% {
    filter: saturate(1.2);
    transform: translate(5px, -3px) scale(0.99) rotate(0deg);
   
	}

  	60% {
    filter: saturate(1.1)  scale(1);
	}

  	75% {
    filter: saturate(1);
    transform: translate(3px, 0px) scale(0.99) rotate(0.1deg);
   
	}

	100% {
    filter: saturate(1.1);
		transform: translate(0px) scale(1) rotate(0deg);
	}
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    transition: all 100ms;
    /* animation-name: ${float2}; */
    animation-duration: 8000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`

const Subtitle = styled.div`
    font-family: antonio-extralight;
    font-size: 71px;

    @media ${device.smallest_laptops} {
        font-size: 60px;
    }

    @media (max-width: 1100px) {
        font-size: 122px;
    }

    @media ${device.desktop} {
        font-size: 70px;
    }

    @media ${device.large_desktop} {
        font-size: 90px;
    }

    text-transform: uppercase;
    color: white;
`

// const ReturnText = styled.div`
//     font-family: antonio-extralight;
//     font-size: 22px;

//     @media ${device.large_desktop} {
//         font-size: 24px;
//     }

//     letter-spacing: 0em;
//     text-transform: uppercase;
//     color: white;
//     transform: translateY(50px);
// `

const ColText = styled.div`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

// background-color: white;
const WhiteLine = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 30vh;
        right: 11vw;
        bottom: 0px;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 10vw;
    }

    @media ${device.large_desktop} {
        font-size: 10px;
    }
`
// background-color: white;
const WhiteLineBefore = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 30vh;
        right: 10vw;
        top: 0px;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 10vw;
    }

    @media ${device.large_desktop} {
        font-size: 10px;
    }
`

const WhiteLineBefore0 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 30vh;
        right: 13vw;
        top: 0px;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 10vw;
    }

    @media ${device.large_desktop} {
        font-size: 10px;
    }
`

// background-color: white;
const WhiteLine3 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 25vh;
        right: 15vw;
        bottom: -8vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 15vw;
    }

    @media ${device.large_desktop} {
        right: 12vw;
        font-size: 11px;
    }
`
// background-color: white;
const WhiteLine2 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 90vh;
        left: 10vw;
        bottom: -160vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 2px;
    }
`

const BgImgStyle = styled.img`
    width: 100%;
    height: 100vh;
    object-fit: fill;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    @media ${device.desktop} {
    }

    @media ${device.smallest_laptops} {
    }
    @media ${device.large_desktop} {
    }

    transition: all 100ms;
    animation-name: ${float};
    animation-duration: 12000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`

const GridImgStyle = styled.img`
    width: 100%;
    max-height: 85vh;
    object-fit: contain;
    display: flex;

    @media ${device.desktop} {
    }

    @media ${device.smallest_laptops} {
    }
    @media ${device.large_desktop} {
    }

    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 12000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`

const GlitchImg = styled.img`
    width: 194px;
    padding-top: 10px;
    filter: saturate(1.2);

    @media ${device.smallest_laptops} {
        width: 182px;
    }

    @media (max-width: 1100px) {
        width: 250px;
    }

    @media ${device.large_desktop} {
        width: 250px;
    }
`

type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

class WelcomePageSlideDesktop extends React.Component<Props, State> {
    state = {
        src: '',
        src_grid: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        this.onLoad(bg_img_url, 'src')
        this.onLoad(grid_img, 'src_grid')
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string, id: 'src' | 'src_grid') => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    ...this.state,
                    [id]: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <WhiteLineBefore0>
                    01110110 <span> - </span>011010011 <span> - </span>
                    101111
                </WhiteLineBefore0>
                <WhiteLineBefore>
                    01001000 <span> - </span>01010101 <span> - </span>
                    01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLineBefore>
                <PageWrapper>
                    <BgImgStyle src={this.state.src} />
                    <GridImgStyle src={this.state.src_grid} />
                    <TextWrapper>
                        <ColText>
                            {/* <ReturnText
                            // className="glitch"
                            // data-glitch={'welcome to'}
                            >
                                welcome to
                            </ReturnText> */}

                            <div style={{ paddingTop: '58px' }} />
                            <Subtitle>
                                <div>
                                    <span id="L">N</span>
                                    <span id="I">R</span>
                                    <span id="G">U</span>
                                    <span id="H">S</span>
                                    <span id="T">.</span>
                                    <span id="H">I</span>
                                    <span id="S">O</span>
                                </div>
                            </Subtitle>

                            <GlitchImg src={welcome_glitch} alt="welcome" />
                        </ColText>
                    </TextWrapper>
                </PageWrapper>
                <WhiteLine>
                    01001000 <span> - </span>01010101 <span> - </span>
                    01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine>
                <WhiteLine3>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine3>
                <WhiteLine2>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                    <span> - </span> 01001000 <span> - </span>01010101
                    <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine2>
            </>
        )
    }
}

export default WelcomePageSlideDesktop
