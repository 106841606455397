import React from 'react'
import styled from 'styled-components'
import * as StatefulIcons from '../statefulIcons'
import polygon_up_white from '../../../assets/icons/polygon_up_white.svg'
import rec_logo from '../../../assets/logo/rec_logo_trans.png'

import { motion } from 'framer-motion'
import { device } from '../../templates/devices/devices'

const Logo = styled.img`
    width: 67px;
    z-index: 10;
    @media ${device.mobile_and_ipad} {
        width: 50px;
    }
`
const MenuContainer = styled.div`
    position: absolute;

    bottom: 0px;

    padding-top: 30px;
    padding-bottom: 30px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media ${device.beyond_ipad_mobile} {
        left: 20px;
        top: 90px;
    }

    @media ${device.mobile_and_ipad} {
        left: 0px;
        top: 60px;
    }
`

const DotsCol = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding-bottom: 20px;
`

const PaginationTextStyled = styled.div`
    color: #838383;
    font-family: Antonio-Light;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    text-align: center;

    background-color: black;
    padding-left: 0px;
    padding-right: 0px;

    @media ${device.beyond_ipad_mobile} {
        font-size: 14px;
        letter-spacing: 4px;
        width: 358px;
        transform: rotate(90deg) translateY(-5px);
    }

    @media ${device.mobile_and_ipad} {
        font-size: 11px;
        letter-spacing: 2px;
        width: 240px;
        transform: rotate(90deg) translateY(0px);
    }
`

const Line = styled.div`
    background-color: #787878;
    height: 100%;
    width: 1px;
`
// const ShortLine = styled.div`
//     background-color: #787878;
//     height: 5%;
//     width: 1px;
// `

const FirstRec = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    padding-top: 10px;
    padding-bottom: 10px;

    @media ${device.beyond_ipad_mobile} {
        border: 1px solid #8f947f;
        height: 230px;
        width: 30px;
    }

    @media ${device.mobile_and_ipad} {
        /* height: 200px;
        width: 25px;
        border: 1px solid #50524b; */

        display: none;
    }
`
const SecondRec = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    height: 200px;
    font-family: Average-Regular;

    @media ${device.beyond_ipad_mobile} {
        border: 1px solid #8f947f;
        width: 30px;
        font-size: 16px;
    }

    @media ${device.mobile_and_ipad} {
        /* height: 200px;
        width: 25px;
        border: 1px solid #50524b;
        font-size: 13px;
        color: white; */
        display: none;
    }
`

const Desktop = styled.div`
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

const ResSpan = styled.div`
    padding-left: 20px;
    @media ${device.mobile_and_ipad} {
        padding-left: 10px;
    }
`

const MobileIpad = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const OnHoverRef = styled.div`
    transition: all 200ms;
    :hover {
        transform: scale(1.2);
    }
`
export interface IPaginationMenuProps {
    goUp: any
    goDown: any
    paginationtextDesktop?: any
    paginationtextMobile?: any
    down: boolean
    up: boolean
    currentSectionIndex: number
    section_list: string[]
}

const MainThemePaginatorMenu: React.SFC<IPaginationMenuProps> = (props) => {
    return (
        <MenuContainer id="paginator">
            <DotsCol>
                {props.section_list.map((id: string, index: number) => {
                    return (
                        <div data-menuanchor={id}>
                            <a href={`#${id}`}>
                                <Desktop>
                                    {index < 5 && (
                                        <StatefulIcons.MainThemePaginatorDotIcon
                                            height="26"
                                            width="26"
                                            isActive={
                                                props.currentSectionIndex -
                                                    1 ===
                                                index
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </Desktop>
                                <MobileIpad>
                                    <StatefulIcons.MainThemePaginatorDotIcon
                                        height="20"
                                        width="20"
                                        isActive={
                                            props.currentSectionIndex - 1 ===
                                            index
                                                ? true
                                                : false
                                        }
                                    />
                                </MobileIpad>
                            </a>
                        </div>
                    )
                })}
            </DotsCol>

            <Line />

            <PaginationTextStyled>
                <div>timeless</div>
                <ResSpan />
                <Logo src={rec_logo} />
                <ResSpan />

                <div>collections</div>
            </PaginationTextStyled>
            <Line />

            <div style={{ paddingTop: '20px' }} />

            <FirstRec>
                <OnHoverRef
                    data-menuanchor={`god_${props.currentSectionIndex - 2}`}
                    style={{
                        opacity: props.up === true ? '1' : '0.3',
                        pointerEvents: props.up === true ? 'auto' : 'none',
                        cursor: props.up === true ? 'pointer' : 'disabled',
                    }}
                >
                    <motion.div whileTap={{ scale: 1.2 }}>
                        <a href={`#${props.currentSectionIndex - 2}`}>
                            <img src={polygon_up_white} alt="arrow" />
                        </a>
                    </motion.div>
                </OnHoverRef>
                <OnHoverRef
                    data-menuanchor={`${props.currentSectionIndex + 1}`}
                    style={{
                        cursor: props.down === true ? 'pointer' : 'disabled',

                        opacity: props.down === true ? '1' : '0.3',
                        pointerEvents: 'auto',
                    }}
                >
                    <motion.div whileTap={{ scale: 1.2 }}>
                        <a href={`#${props.currentSectionIndex}`}>
                            <div style={{ paddingTop: '10px' }} />
                            <img
                                style={{ transform: 'rotate(180deg)' }}
                                src={polygon_up_white}
                                alt="arrow"
                            />
                        </a>
                    </motion.div>
                </OnHoverRef>
            </FirstRec>
            <SecondRec>
                <span style={{ transform: 'rotate(90deg)' }}>
                    <Desktop>{props.paginationtextDesktop}</Desktop>
                    <MobileIpad>{props.paginationtextMobile}</MobileIpad>
                </span>
            </SecondRec>
        </MenuContainer>
    )
}

export default MainThemePaginatorMenu
