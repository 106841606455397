import React from 'react'
import styled from 'styled-components'
import nath from '../../../assets/slidesimg/nath.png'
import './styles.css'
import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import '../styles.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

// filter: saturate(1);
const GodImg = styled.img`
    margin-top: 10vh;
    display: none;
    width: 40vw;
    max-height: 60vh;
    object-fit: contain;
`

const StyledVideo = styled.video`
    width: auto;
    max-height: 80vh;
    position: absolute;
    left: -18vw;
    bottom: -15vh;

    @media ${device.ipad} {
        bottom: 0vh;
        left: 5vw;
    }
`

const SmallLine = styled.div`
    background-color: white;
    width: 10px;
    height: 1px;
`
const SmallLine2 = styled.div`
    background-color: white;
    width: 50px;
    height: 1px;
`

const TextWrap2 = styled.div`
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    position: absolute;
    right: 10vw;
    z-index: 7;
    top: 5vh;

    @media ${device.ipad} {
        top: 20vh;
        right: 20vw;
    }
`

const Text1 = styled.div`
    font-size: 28px;
    font-family: Antonio-ExtraLight;
    opacity: 1;
    color: #24ff00;
`

const Text2 = styled.a`
    font-size: 16px;
    font-family: Antonio-ExtraLight;
    color: white;
    padding-bottom: 5px;
    text-decoration: none;
`
const Col = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`

const Col2 = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    transform: translateY(0px);
`

type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

class HomepageSlide3Mobile extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        let url = nath

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <PageWrapper>
                    {/* <DiagoLine /> */}
                    <GodImg
                        src={nath}
                        alt="god"
                        style={{
                            paddingTop: '0px',
                        }}
                    />

                    <TextWrap2>
                        <Col>
                            <Text1>ENGINEER.</Text1>
                            <Text1>ARTIST.</Text1>
                            <Text1>FOUNDER.</Text1>
                        </Col>
                        <div style={{ paddingTop: '20px' }} />
                        <SmallLine />
                        <div style={{ paddingTop: '20px' }} />
                        <div
                            style={{
                                fontFamily: 'Antonio-Regular',
                                fontSize: '12px',
                            }}
                        >
                            NATHALIA RUS
                        </div>
                        <div style={{ paddingTop: '20px' }} />
                        <SmallLine2 />
                        <div style={{ paddingTop: '20px' }} />
                        <Col2>
                            <Text2
                                className="glitch-2"
                                data-glitch-2={'Instagram'}
                                href="https://www.instagram.com/yeahgirlscode"
                                target="_blank"
                                style={{ top: '0px' }}
                            >
                                Instagram
                            </Text2>
                            <Text2
                                href="https://twitter.com/yeahgirlscode"
                                target="_blank"
                                className="glitch-2"
                                data-glitch-2={'Twitter'}
                                style={{ top: '30px' }}
                            >
                                Twitter
                            </Text2>
                            <Text2
                                className="glitch-2"
                                data-glitch-2={'Behance'}
                                href="https://www.behance.net/nathaliarus"
                                target="_blank"
                                style={{ top: '60px' }}
                            >
                                Behance
                            </Text2>

                            <Text2
                                className="glitch-2"
                                data-glitch-2={'Soundcloud'}
                                href="https://soundcloud.com/nathaliarus"
                                target="_blank"
                                style={{ top: '90px' }}
                            >
                                Soundcloud
                            </Text2>
                        </Col2>
                    </TextWrap2>

                    <StyledVideo
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        preload={'auto'}
                        data-keepplaying
                        ref={(ref) => 'video_nath'}
                        onLoadedData={() =>
                            this.setState({ isVideoLoading: false })
                        }
                        style={{ opacity: this.state.isVideoLoading ? 0 : 1 }}
                    >
                        <source src={'/nath.mp4'} type="video/mp4" />
                    </StyledVideo>
                </PageWrapper>
            </>
        )
    }
}

export default HomepageSlide3Mobile
