import React from 'react'
import styled, { keyframes } from 'styled-components'

import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import './styles.css'
import naiads_desktop_bk from '../../../assets/naiads/naiads_desktop_bk.png'

import naiads_wholebk from '../../../assets/naiads/naiads_wholebk.png'

const PageWrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        padding-top: 6vh;
        position: relative;
    }
`

const WhiteLine = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 30vh;
        right: 11vw;
        bottom: 0px;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 10vw;
    }

    @media ${device.large_desktop} {
        font-size: 10px;
    }
`

// background-color: white;
const WhiteLine3 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 25vh;
        right: 15vw;
        bottom: -8vh;
        z-index: 5;
        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 9px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        right: 15vw;
    }

    @media ${device.large_desktop} {
        right: 12vw;
        font-size: 11px;
    }
`
// background-color: white;
const WhiteLine2 = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        width: 1px;
        display: block;
        height: 90vh;
        left: 15vw;
        bottom: -165vh;
        z-index: 5;

        overflow-wrap: break-word;
        font-family: Antonio-Regular;
        font-weight: 800;
        font-size: 10px;
        letter-spacing: 2px;
    }
`

const NaiadsMainImg = styled.img`
    width: 80vw;
    object-fit: cover;
    z-index: 0;

    @media ${device.large_desktop} {
        width: 80vw;
    }
`
const float = () => keyframes`
	0% {


		transform: translate(0px) rotate(0deg);
	}
	25% {
    transform: translate(-3px, 5px) scale(1.05) rotate(0.3deg);
   
	}

	50% {
    opacity 1;
    transform: translate(-5px, 0px) scale(1.1) rotate(0deg);
   
	}

  	75% {
    transform: translate(-3px, -5px) scale(1.05) rotate(-0.3deg);
   
	}

	100% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.0);
		transform: translate(0px) rotate(0deg);
	}
`
const NaiadsSubBk = styled.img`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        top: 8vh;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        filter: saturate(2);
        opacity: 0.5;

        transition: all 100ms;
        animation-name: ${float};
        animation-duration: 12000ms;
        transition: all ease-in-out infinite;
        animation-iteration-count: infinite;
    }
`

type Props = {}

type State = {
    src: any
}

class HomepageSlide002 extends React.Component<Props, State> {
    state = {
        src: '',
    }

    componentDidMount() {
        let url = naiads_desktop_bk

        this.onLoad(url)
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <NaiadsSubBk src={naiads_wholebk} alt="" />
                <PageWrapper>
                    <NaiadsMainImg src={this.state.src} alt="naiads" />
                </PageWrapper>
                <WhiteLine>
                    01001000 <span> - </span>01010101 <span> - </span>
                    01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine>
                <WhiteLine3>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine3>
                <WhiteLine2>
                    01001000 <span> - </span>01010101 <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                    <span> - </span> 01001000 <span> - </span>01010101
                    <span> - </span>01001101
                    <span> - </span>01000001 <span> - </span>01001110
                </WhiteLine2>
            </>
        )
    }
}

export default HomepageSlide002
