import React from 'react'
import styled, { keyframes } from 'styled-components'

import grid_img from '../../../assets/slidesimg/welcome/mobile_welcome_grid.png'
import welcome_glitch from '../../../assets/slidesimg/welcome/mobile_welcome_glitch.png'
import mobile_deco from '../../../assets/slidesimg/welcome/mobile_welcome_deco.png'

import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'
import './styles.css'

const PageWrapper = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-left: 4vw;
`

const float2 = () => keyframes`
	0% {
    filter: saturate(1.1);
		transform: translate(0px) scale(1) rotate(0deg);
	}
	25% {
	    filter: saturate(1);
    transform: translate(3px, -5px) scale(0.99) rotate(0.1deg);
   
	}

    	40% {
    filter: saturate(1.1) scale(1);
	}

	50% {
    filter: saturate(1.2);
    transform: translate(5px, -3px) scale(0.99) rotate(0deg);
   
	}

  	60% {
    filter: saturate(1.1)  scale(1);
	}

  	75% {
    filter: saturate(1);
    transform: translate(3px, 0px) scale(0.99) rotate(0.1deg);
   
	}

	100% {
    filter: saturate(1.1);
		transform: translate(0px) scale(1) rotate(0deg);
	}
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    transition: all 100ms;
    /* animation-name: ${float2}; */
    animation-duration: 8000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
    padding-left: 10vw;
    padding-top: 20vh;
`

const ReturnText = styled.div`
    font-family: antonio-extralight;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: white;

    @media (min-width: 500px) {
        font-size: 45px !important;
    }
`

const ColText = styled.div`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const GridImgStyle = styled.img`
    width: 100%;
    max-height: 85vh;
    object-fit: contain;
    display: flex;
    filter: saturate(1.05);
`

const GlitchImg = styled.img`
    width: 170px;
    height: 50px;
    padding-top: 5px;
    filter: saturate(1.5);
    @media (min-width: 500px) {
        width: 250px;
    }
`

const MobileDecoLine = styled.img`
    top: 0px;
    position: absolute;
    right: 10px;
    z-index: 10;

    @media (min-width: 500px) {
        height: 50vh;
    }
`
type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

class WelcomePageSlideMobile extends React.Component<Props, State> {
    state = {
        src: '',
        src_grid: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        this.onLoad(grid_img, 'src_grid')
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string, id: 'src' | 'src_grid') => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    ...this.state,
                    [id]: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <PageWrapper>
                    <GridImgStyle src={this.state.src_grid} />
                    <TextWrapper>
                        <ColText>
                            <ReturnText
                                style={{
                                    fontSize: '14px',
                                    transform: 'translateY(10px)',
                                }}
                            >
                                welcome to
                            </ReturnText>
                            <ReturnText style={{ fontSize: '38px' }}>
                                NRUS.IO
                            </ReturnText>

                            <GlitchImg
                                src={welcome_glitch}
                                alt="welcome"
                                style={{
                                    transform: 'translateY(-5px)',
                                }}
                            />
                        </ColText>
                    </TextWrapper>
                </PageWrapper>{' '}
                <MobileDecoLine src={mobile_deco} alt="deco" />
            </>
        )
    }
}

export default WelcomePageSlideMobile
