import React from 'react'
import styled, { keyframes } from 'styled-components'
import return_arrow from '../../../assets/icons/golden_arrow_left.svg'
import dio from '../../../assets/slidesimg/dio.jpg'
import green_diago from '../../../assets/slidesimg/green_diago.png'
import { Howl } from 'howler'
import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

const float = () => keyframes`
	0% {
    opacity:$ 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 0.8;

	
    transform: translate(-3px, 5px) scale(0.99) rotate(0.3deg);
   
	}

	50% {
    opacity 1;

    transform: translate(-5px, 0px) scale(0.98) rotate(0deg);
   
	}

  	75% {
    opacity 0.6;

	
    transform: translate(-3px, -5px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.0);
		transform: translate(0px) rotate(0deg);
	}
`

const float2 = () => keyframes`
	0% {
    opacity: 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 1;

	
    transform: translate(3px, -5px) scale(0.99) rotate(0.3deg);
   
	}

    	40% {
    opacity 1;
	}

	50% {
    opacity 0.5;
    transform: translate(5px, -3px) scale(0.98) rotate(0deg);
   
	}

  	60% {
    opacity 1;
	}

  	75% {
    opacity 1;

	
    transform: translate(3px, 0px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		transform: translate(0px) rotate(0deg);
	}
`
// filter: saturate(1);
const GodImg = styled.img`
    width: 270vw;
    height: 100vh;
    object-fit: contain;
    position: absolute;
    transition: all 100ms;
    top: 0;
    animation-name: ${float};
    animation-duration: 12000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`
// const StyledVideo = styled.video`
//     height: 120%;
//     top: 0;
//     left: 0;
//     opacity: 1;
//     transition: opacity, 0.2s ease-in-out;
//     transform: translate(25vw, -10vh);
// `

const NeoImg = styled.img`
    position: absolute;
    height: 100vh;
    display: none;
    left: 0vw;

    z-index: 7;
`

const TextWrapper = styled.div`
    display: flex;
    margin-top: -10vh;
    margin-left: 0vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 8000ms;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
`

const Subtitle = styled.div`
    font-family: antonio-extralight;
    font-size: 18px;

    text-transform: uppercase;
    color: white;

    opacity: 1;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ReturnText = styled.div`
    font-family: antonio-extralight;
    font-size: 80px;

    letter-spacing: 0em;
    text-transform: uppercase;
    color: white;
    transform: translateY(50px);
`

const StyledLink = styled.a`
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

type Istyled2 = {
    idHovered: string
}
const Arrow = styled.img<Istyled2>`
    transition: 150ms;

    opacity: 1;
    width: 20px;

    @media ${device.desktop} {
        width: 20px;
    }

    @media ${device.large_desktop} {
        width: 30px;
    }
    transform: ${(props) =>
        props.idHovered === 'arrow'
            ? 'rotate(0deg) translate(0px, 0px)'
            : 'rotate(0deg) translate(0px, 0px)'};
`

// background-color: white;
const WhiteLine = styled.div`
    position: absolute;
    width: 1px;
    height: 30vh;
    right: 30vw;
    bottom: -15vh;
    z-index: 5;
    overflow-wrap: break-word;
    font-family: Antonio-Regular;
    font-weight: 800;
    font-size: 5px;
    letter-spacing: 0px;
`

// background-color: white;
const WhiteLine1 = styled.div`
    position: absolute;
    width: 1px;
    height: 30vh;
    right: 35vw;
    bottom: -10vh;
    z-index: 5;
    overflow-wrap: break-word;
    font-family: Antonio-Regular;
    font-weight: 800;
    font-size: 4px;
    letter-spacing: 0px;
`

type Props = {}

type State = {
    src: any
    idHovered: string
    isVideoLoading: boolean
}

let sound = new Howl({
    src: ['/electric_glitch.wav'],
    autoplay: true,
    loop: false,
    volume: 0.01,
    onend: function () {
        console.log('Finished!')
    },
})

class HomepageSlide1Mobile extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
        isVideoLoading: true,
    }
    video: any
    componentDidMount() {
        let url = dio

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        let { idHovered } = this.state

        return (
            <>
                <PageWrapper>
                    <WhiteLine>
                        01001000 <span> - </span>01010101 <span> - </span>
                        01001101
                        <span> - </span>01000001 <span> - </span>01001110
                    </WhiteLine>
                    <WhiteLine1>
                        01001000 <span> - </span>01010101 <span> - </span>
                        01001101
                        <span> - </span>01000001 <span> - </span>01001110
                    </WhiteLine1>
                    <NeoImg src={green_diago} alt="neon" />

                    <GodImg
                        src={dio}
                        alt="god"
                        style={{
                            paddingTop: '0px',
                        }}
                    />

                    {/* <StyledVideo
                        autoPlay
                        muted
                        loop
                        data-keepplaying
                        preload={'auto'}
                        ref={(ref) => 'video_dio'}
                        onLoadedData={() =>
                            this.setState({ isVideoLoading: false })
                        }
                        style={{ opacity: this.state.isVideoLoading ? 0 : 1 }}
                    >
                        <source src={'/dio_video.mp4'} type="video/mp4" />
                    </StyledVideo> */}

                    <TextWrapper>
                        <StyledLink
                            href="https://gods.nrus.io"
                            target="_blank"
                            onMouseOver={() => {
                                this.setIdHovered('arrow')
                                sound.play()
                            }}
                            onMouseLeave={() => this.setIdHovered('')}
                        >
                            <ReturnText
                            // className="glitch"
                            // data-glitch={'the gods'}
                            >
                                <div>
                                    <span id="I">G</span>
                                    <span id="I">O</span>
                                    <span id="I">D</span>
                                    <span id="I">S</span>
                                </div>
                            </ReturnText>

                            <div style={{ paddingTop: '58px' }} />
                            <Subtitle>
                                <div> EXPLORE</div>
                                <div style={{ paddingTop: '20px' }} />
                                <Arrow
                                    idHovered={idHovered}
                                    src={return_arrow}
                                    alt="return"
                                />
                            </Subtitle>
                        </StyledLink>
                    </TextWrapper>
                </PageWrapper>
            </>
        )
    }
}

export default HomepageSlide1Mobile
