import React from 'react'
import styled, { keyframes } from 'styled-components'

import mania from '../../../assets/slidesimg/mania.png'

import est from '../../../assets/slidesimg/est.png'

import { device } from '../../templates/devices/devices'
import '../../atoms/effects/lights.css'

const PageWrapper = styled.div`
    display: none;
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
`

const float2 = () => keyframes`
	0% {
    opacity: 1;

		transform: translate(0px) rotate(0deg);
	}
	25% {
    opacity 1;

	
    transform: translate(3px, -5px) scale(0.99) rotate(0.3deg);
   
	}

    	40% {
    opacity 1;
	}

	50% {
    opacity 0.5;
    transform: translate(5px, -3px) scale(0.98) rotate(0deg);
   
	}

  	60% {
    opacity 1;
	}

  	75% {
    opacity 1;

	
    transform: translate(3px, 0px) scale(0.99) rotate(-0.3deg);
   
	}

	100% {
    opacity: 1;
		transform: translate(0px) rotate(0deg);
	}
`
// filter: saturate(1);
const GodImg = styled.img`
    margin-top: 0vh;
    width: auto;
    height: 40vh;
    object-fit: contain;

    transform: translateX(-13vw);
`

const ComingSoonImg = styled.img`
    height: 15vh;
    transform: rotate(-269deg);
    position: absolute;
    left: 30vw;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    z-index: 5;
    transition: all 100ms;
    animation-name: ${float2};
    animation-duration: 8000ms;
    animation-transition: all ease-in-out infinite;
    transition: all ease-in-out infinite;
    animation-iteration-count: infinite;
    position: absolute;
    left: 20vw;
    top: 30vh;
    width: auto;
    text-align: left;
`

const Subtitle = styled.div`
    font-family: antonio-extralight;
    font-size: 21px;
    text-transform: uppercase;
    color: grey;
    transform: translateY(-10vh);
    opacity: 0.8;
    width: 100vw;
    letter-spacing: 0px !important;
    text-align: left;

    @media ${device.ipad} {
        font-size: 26px;
    }
`

const StyledLink = styled.div`
    text-decoration: none;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: auto;
`

const EstTxt = styled.div`
    font-family: Antonio-Regular;

    opacity: 0.2;
    width: auto;
    color: white;
    text-align: left;
    transform: translateY(-15vh);

    @media ${device.ipad} {
        font-size: 38px;
    }
`
// background-color: white;
const WhiteLine = styled.div`
    position: absolute;
    width: 1px;
    height: 60vh;
    left: 30vw;
    bottom: -40vh;
    z-index: 5;
    overflow-wrap: break-word;
    font-family: Antonio-Regular;
    font-weight: 800;
    font-size: 7px;
    letter-spacing: 0px;

    @media ${device.ipad} {
        left: 35vw;
        height: 55vh;
        bottom: -35vh;
    }
`
type Props = {}

type State = {
    src: any
    idHovered: string
}

class HomepageSlide2Mobile extends React.Component<Props, State> {
    state = {
        src: '',
        idHovered: '',
    }
    video: any
    componentDidMount() {
        let url = mania

        this.onLoad(url)
    }

    setIdHovered = (id: string) => {
        this.setState({ ...this.state, idHovered: id })
    }

    onLoad = (url: string) => {
        let image = document.createElement('img')
        image.src = url

        image.onload = () => {
            let f = () =>
                this.setState({
                    src: image.src,
                })
            setTimeout(() => {
                f()
            }, 100)
        }
    }

    render() {
        return (
            <>
                <PageWrapper>
                    <WhiteLine>
                        01001000 <span> - </span>01010101 <span> - </span>
                        01001101
                        <span> - </span>01000001 <span> - </span>01001110
                    </WhiteLine>
                    <GodImg src={mania} alt="god" />

                    <TextWrapper>
                        <StyledLink
                            // href="https://gods.nrus.io"
                            // target="_blank"
                            onMouseOver={() => {
                                this.setIdHovered('arrow')
                            }}
                            onMouseLeave={() => this.setIdHovered('')}
                        >
                            <EstTxt> COMING SOON </EstTxt>
                            <div style={{ paddingTop: '5px' }} />
                            <Subtitle
                                className="glitch-2"
                                data-glitch-2={'8 types of love'}
                            >
                                8 types of love
                            </Subtitle>
                            <div style={{ paddingTop: '8vh' }} />
                        </StyledLink>
                    </TextWrapper>
                    <ComingSoonImg src={est} alt="coming soon" />
                </PageWrapper>
            </>
        )
    }
}

export default HomepageSlide2Mobile
